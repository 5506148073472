.posts__btn{
   @media only screen and (max-width: 426px){
      margin: 0 5px;
   }
   @media only screen and (min-width: 426px) and (max-width: 768px) {
      margin: 0 25px;
   }
   border-radius: 5px;
background: rgba(29, 63, 129, 0.06);
color: #1D3F81;
font-weight: 500;
border: none;
padding: 10px;
text-align: center;
align-items: center;
cursor: pointer;
margin: 0 30px;
}
.post-list{
   @media only screen and (min-width: 320px) and (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
   }
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   gap: 20px
}
.post-item{
   @media only screen and (max-width: 768px){
      max-width: 165px;
   }
   text-decoration: none;
   color: $black;
   max-width: 290px;
   border-radius: 5.02px;
background: rgba(29, 63, 129, 0.10);
   &__image{
      width: 100%;
      height: 186px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #fff;
   }
   &__content{
   padding: 20px;
   }
   &__footer{
display: flex;
justify-content: space-between;
   }
}
.post-content {
   
   &__text {
      max-height: 35px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
   }
 }
 .post-item__footer{
    @media only screen and (max-width: 768px){
      padding: 20px 0px 0 0px;
   }
   max-width: 50%;
   padding: 20px 20px 0 10px;
 }
 .post-calendar,
 .post-views{
    
   display: flex;
   justify-content: space-around;
 }
.post-calendar{
   @media only screen and (max-width: 768px){
      margin-right: 15px;
   }
}
.fullpost{
  
   &__container{

   }
}
.post-full{
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   
   &__image{
      width: 100%;
      height: 433px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
   }
}