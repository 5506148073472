.footer-up {

   &__container {
      display: flex;
justify-content: space-between;
   }
}

.footer-down {
background-color: $main-color;
   &__container {
      display: flex;
      justify-content: center;
   }
}

.privat {
color: $orange;
   &__political {
   }

   &__copyright {
      padding-left: 27px;
      margin-top: 7px;
   }
}

.footer-body {
   
background-color: $main-color;
color: #FFF;
   &__container {
      @media (max-width: 768px){
         flex-direction: column-reverse;
      }
      display: flex;
      justify-content: space-between;
   }

   &__info {
   }

   &__map {
   }
}
.info-footer {
   @media (max-width: 768px){
      margin: 0 auto;
   }
   max-width: 250px;
   &__time {
      @media (max-width: 768px){
        display: block;
        
      }
      display: flex;
      justify-content: center;
      padding: 13px;
   }

   &__location {
      @media (max-width: 768px){
         display: block;
         padding: 0; 
       }
      display: flex;
      justify-content: center;
      padding: 13px;
   }

   &__mail {
      @media (max-width: 768px){
         display: block; 
       }
      display: flex;
      justify-content: center;
      padding: 13px;
   }
}
.time-footer {

   &__image {
      @media (max-width: 768px){
         padding-left: 44%;
      }
      padding-right: 18px;
   }

   &__text {
      @media (max-width: 768px){
         padding-left: 10%;
      }
   }
}
.location-footer {

   &__image {
      @media (max-width: 768px){
         padding-left: 44%;
         margin: 0 auto;
      }
      margin-right: 20px;
   }

   &__text {
      @media (max-width: 768px){
         width: 300px;
      }
   }
}
.mail-footer {

   &__image { 
      @media (max-width: 768px){
         padding-left: 43%;
         margin: 0 auto;
      }
      padding-left: 33px;
      margin-right: 20px;
   }

   &__text {
   }
}
.footer-navigation {
   
   &__title {
   }

   &__list {
      @media (max-width: 768px){
         text-align: center;
      align-items: center;
      }
      list-style: none;
      
   }

   &__link {
      text-decoration: none;
      color: #FFF;
   font-size: 14.498px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px; /* 165.543% */
      display: block;
      text-decoration: none;
      margin-top: 8px;
&:hover{
   color: $orange;
}
   }
}
.title-footer {
   @media (max-width: 768px){
      text-align: center;
   align-items: center;
   }
   color: $orange;
}
.map-footer{
   @media only screen and (max-width: 768px){
      width: 100%;
      height: 100px;
   }
   margin-top: 20px;
}
.privat__political{
   color: var(--Main-color-1, #FE7700);
text-align: center;
font-size: 18px;
font-weight: 700;
letter-spacing: -0.54px;
text-decoration: none
}