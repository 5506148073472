.contacts-container {
}

.pages-title{
   color: #FFF;
 border-radius: 5px;
 background: linear-gradient(0deg, #1D3F81 0%, #1D3F81 100%), #F4F7FB;
 padding: 46px 103px;
 }
.contacts-body {
	@media (max-width: 426px){
		display: block;
	}
display: flex;
justify-content: space-around;
		&__content {
		}

		&__map {
		}
}
.content-contacts {
   align-items: center;
		&__phone {
			@media (max-width: 426px){
				display: block;
			}
			display: flex;
         justify-content: space-around;
         margin: 30px;
		}

		&__location {
			@media (max-width: 426px){
				display: block;
			}
			display: flex;
         justify-content: space-around;
         margin: 30px;
		}

		&__mail {
			@media (max-width: 426px){
				display: block;
			}
			display: flex;
         justify-content: space-around;
         margin: 30px;
		}
}
.phone-contacts {
	
		&__icon {
			@media (max-width: 426px){
				padding-left: 43%;
			}
         margin-left: 15px;
         border-radius: 5px;
background: #1D3F81;
padding: 12px 4px;
		}

		&__info {
			@media (max-width: 426px){
				align-items: center;
				text-align: center;
			}
		}

		&__title {
         color: var(--, #585555);

letter-spacing: -0.42px;
		}
      &__number{
         color: #000;
         font-size: 14.498px;
         text-decoration: none;
         font-weight: 500;
         line-height: 24px; /* 165.543% */
      }
}
.location-contacts {
align-items: center;
		&__icon {
			@media (max-width: 426px){
				padding-left: 47%;
			}
         border-radius: 5px;
         background: #1D3F81;
         padding: 6px 4px;
		}

		&__map {
			@media (max-width: 426px){
				margin: 0 auto;
				max-width: 275px;
				align-items: center;
				text-align: center;
			}
         max-width: 350px
		}

		&__time {
			@media (max-width: 426px){
				margin: 0 auto;
				align-items: center;
				text-align: center;
			}
		}
}
.mail-contacts {
   align-items: center;
		&__icon { 
			@media (max-width: 426px){
				padding-left: 39%;
			}
         margin-left: 25px;
         border-radius: 5px;
         background: #1D3F81;
         padding: 6px 4px;
         margin-right: 15px;
		}

		&__info {
			@media (max-width: 426px){
				margin: 0 auto;
				align-items: center;
				text-align: center;
			}
		}

		&__title {
		}

		&__text {
		}
}
