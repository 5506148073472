.about{
   background: var(--background-color-white, #F5F5F5);
   &__container{
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
     
   }
   &__banner{
      width: 100%;
      height: 70vh;
      background-image: url(../images/other/banner.png);
      background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   }
   &__title{
      padding: 116px 0 50px;
      color: $orange;
font-size: 35px;
font-weight: 700;
letter-spacing: -1.4px;
text-transform: uppercase;
   }
   &__banner-content{
      display: flex;
      justify-content: space-around;
   }
}
.about-block{
   color: $orange;
   font-weight: 700;
letter-spacing: -0.42px;
}

.about-content{
   border-radius: 5px;
background: #FFF;
   padding: 60px 103px;
   &__title{
      text-align: center;
      align-items: center;
      margin-bottom: 50px;
   }
   
}
.text-about {
   & h5{
      padding: 20px 20px 39px;
   }
   &__item{
      display: grid;
      grid-template-columns: 1fr 50fr;
      gap: 10px;
      align-items: center;
      padding: 8px;
   }
   &__profit{
      padding-bottom: 4px;
   }
}
.about-benefits{
   border-radius: 5px;
background: #FFF;
   &__title{
      color: var(--Black, #282739);
text-align: center;
font-size: 25px;
font-weight: 700;
text-transform: uppercase;
   }
   &__subtitle{
text-align: center;
font-weight: 500;
line-height: 24px; /* 165.543% */
margin-bottom: 65px;
   }
}
.benefits-content{
   @media (max-width: 768px){
      display: flex;
      flex-wrap: wrap
   }
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   gap: 20px;
   margin-bottom: 75px;
}
.item-benefits{
   border-radius: 5px;
border: 1px solid var(--gray-200, #EAEBED);
padding: 26px 22px;
&__title{
  margin: 21px 0;
}
}