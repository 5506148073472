
$backgroundImage1: url("../images/home/auto.png");
$backgroundImage2: url("../images/home/fermer.png");
$backgroundImage3: url("../images/home/factory.png");


$backgroundImage: '';
.home{
   background: var(--background-color-white, #F5F5F5);
}
.home-filter{
   @media (max-width: 768px){
      display: none
   }
  display: flex;
  justify-content: space-around;
   background: #FFF;
   &__item{
      position: relative;

   }
   .auto{
      width: 393px;
      height: 292px;
      background-image: url(../images/home/auto.png);
      background-position: center;
   background-repeat: no-repeat;
   cursor: pointer;
   }
   .fermer{
      width: 393px;
      height: 292px;
      background-image: url(../images/home/fermer.png);
      background-position: center;
   background-repeat: no-repeat;
   cursor: pointer;
   }
   .factory{
      width: 393px;
      height: 292px;
      background-image: url(../images/home/factory.png);
      background-position: center;
   background-repeat: no-repeat;
   cursor: pointer;
   }
}
.item-filter__title{
   position: absolute;
   bottom: 20%;
   left: 10%;
   color: #FFF;
   font-size: 18px;
text-align: center;
font-weight: 700;
letter-spacing: -0.54px;
&:hover{
   color: $orange;
}
}
.home-advertisment{
   @media (max-width: 768px){
      display: none;
      & img{
         width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
      }
   }
   background: #FFF;
   display: flex;
   justify-content: space-between;
   padding: 48px 38px;
}
.swiper-container{
   @media (max-width: 768px){
      display: none;}
}
.swiper-slide {
   & Link{
   text-decoration: none
   }
   .main-swiper {
      
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      height: 50vh;
      cursor: pointer;
   
      &.slide-1 {
         background-image: url("../images/home/auto.png");
      }
   
      &.slide-2 {
         background-image: url("../images/home/fermer.png");
      }
   
      &.slide-3 {
         background-image: url("../images/home/factory.png");
      }
   }
}

// Ваш стиль для радіобоксів
.radio-buttons {
   display: flex;
   justify-content: center;
   align-items: flex-end;
   margin-bottom: 10px;
   position: absolute;
   bottom: 15%;
   left: 45%;
   z-index: 80;

   input {
      margin: 0 5px;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 12px;
      background: var(--background-color, #585555);
      border: 2px solid #585555;
      cursor: pointer;
   }

   input:checked {
      background: $orange; // Замініть це на ваш змінний колір
      border: 2px solid $orange; // Тут також можна використовувати вашу змінну
   }
}
.swiper-title{
   max-width: 453px;
   color: var(--background-color-white, #F5F5F5);
font-size: 48px;
font-weight: 700;
line-height: 94.4%; /* 45.312px */
letter-spacing: -1.44px;
padding: 87px 102px 22px;
}
.swiper-desc{
   color: #FFF;
font-weight: 500;
line-height: 156.4%; /* 21.896px */
padding: 0 105px;
}
.home .products-content{
   display: flex;
   justify-content: space-between;
}
.swiper-link{
   text-decoration: none;
}