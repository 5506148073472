.header-up {
   @media (max-width: 768px){
      display: none
   }
   background: rgba(4, 42, 116, 0.10);
   box-shadow: 0px 11px 20px 0px rgba(0, 0, 0, 0.05);
   align-items: center;
   text-align: center;
		&__container {
         display: flex;
justify-content: space-between;
		}

		&__mail {display: flex;
         justify-content: center;
         align-items: center;
		}

		&__navigation {
		}
}
.mail-header {

		&__text {color: $main-color;
         text-align: center;
         font-size: 14px;
         font-style: normal;
         font-weight: 700;
         line-height: normal;
         padding-left: 5px;
		}
}
.navigation {

		&__block {
         display: flex;
         justify-content: space-between;
		}

		&__list { 
         align-items: center;
         list-style: none;
         
		}

		&__link {
         color: #343434;
         text-align: center;
         font-size: 14px;
         font-style: normal;
         font-weight: 500;
         line-height: normal;
         text-decoration: none;
         margin: 0px 30px;
         &:hover{
            color: $orange;
         }
		}
}
.btn-login {
display: flex;
align-items: center;
border: none;
background-color: transparent;
cursor: pointer;

&__container{
   display: flex;
   justify-content: space-between;
   .btn-login__text{
      padding: 5px;
   }
}
&__text {
         color: $main-color;
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
&:hover{
   color: $orange;
}
		}
}
.btn-login__logout{
   background-color: transparent;
   border: none;
   color: $main-color;
   font-weight: 700;
   cursor: pointer;
   &:hover{
      color: $orange;
   }
}

.header-body {
   align-items: center;
   text-align: center;
   
   &__container {
      display: flex;
justify-content: space-between;
   }

   &__logo {
      
   }

   &__time {
      @media (max-width: 768px){
         display: none
      }
      display:flex;
      align-items:center
   }
   
   
}

.form {
   display: flex;
   justify-content: space-between;
}
.form-input {
 @media (max-width: 768px){
   &__search {
      max-width: 70px;
      margin: 0 10px;
   }
   }
		&__search {
         width: 500px;
         align-items: center;
         padding: 15px;
		}
}



.header-down {
   align-items: center;
   text-align: center;
   background-color: $main-color;
   &__container {display: flex;
      justify-content: space-between;
   }

   &__category {
      width: 210px;
      display: flex;
      background-color: $orange;
      padding: 14px 8px;
      color: #fff;
      
   }
}
.btn-header{
   width: 250px;
display: flex;
justify-content: space-between;
align-items:center;
text-align: center;

}
.category-header {

   &__text {
      padding-left: 5px;
   }
}




.search-box {
   @media (max-width: 426px){
      top: 20%;
      left: 0;
   }
   @media (min-width: 426px) and (max-width: 768px){
      top: 20%;
      left: 0
   }
   position: absolute;
   top: 31%;
   left: 30%;
   width: 100%;
   max-width: 550px; /* Задайте максимальну ширину за потребою */
   background-color: #fff;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   border: 1px solid #ccc;
   z-index: 10000;
}

/* Стилі для списку результатів */
.search-box ul {
   list-style: none;
   padding: 0;
   margin: 0;
}

.search-box li {
   padding: 8px;
   border-bottom: 1px solid #eee; /* Роздільник між елементами */
}

.search-box li:last-child {
   border-bottom: none; /* Немає роздільника для останнього елемента */
}
.result-image{
   width: 150px;
      height: 150px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
}