.floating-catalog {
   &__title{
      display: flex;
      font-size: 24px;
   color: #000; /* Замість $black, якщо це не визначено у вас */
   margin: 0; /* Якщо є невідомі відступи, скидайте їх */
   align-items: flex-start;
   }
   width: 97%;
   height: 100%;
   position: absolute;
   top: 233px; /* Адаптуйте за потребою */
   left: 30px; /* Адаптуйте за потребою */
   z-index: 1000; /* Адаптуйте за потребою */
   background-color: #FFF8F2;
   /* Додайте інші стилі за потребою */
}
