.login-container{
   background: #fff;
   position: relative
}
.btn-close{
   justify-content: end;
   position:absolute;
   top: 5%;
   left: 90%;
   border: none;
   background-color: transparent;
   cursor: pointer;
}
.login-btns{
   max-width: 150px;
   position:absolute;
   top: 5%;
   left: 33%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   text-align: center;
   &__space{
      padding-top: 17px;
   }
}
.login-btn{
   color: #585555;
   font-size: 18px;
   font-weight: 700;
   letter-spacing: -0.54px;
   border: none;
   background-color: transparent;
   cursor: pointer;
   &.active{
      color: $orange;
   }
}
.header .close{
   display: none;
}
.exit-content,
.register-content{
   display: flex;
   flex-direction: column;
   margin-top: 50px;
}
.exit-content{
   
}