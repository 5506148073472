@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,regular,500,600,700,800,900,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

$main-color: var(--_2, rgba(4, 42, 116, 0.90));
$orange: var(--_1, #FE7700);
$black: #000;

*{
   margin: 0;
   padding: 0;
 }
 body {
   color: $black;
   font-family: 'Montserrat';
   font-weight: 400;
   font-size: 14px;
 }
 .container{
   padding: 18px 30px
 }
 .h1{
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
 }
 
 .h2{
  
  color: var(--Black, #282739);
text-align: center;
font-size: 25px;
font-weight: 700;
text-transform: uppercase;
 }
 .h3{
text-align: center;
  font-size: 22px;
  font-weight: 600;
 }
 .h4{
  font-size: 18px;
font-weight: 700;
letter-spacing: -0.54px;
 }
 h5{
  font-size: 14.498px;
  font-weight: 500;
line-height: 24px;
 }
 h6{
font-size: 14px;
font-weight: 400;
letter-spacing: -0.42px;
 }

// Компоненти
 .time {

  &__text {
     max-width: 168px;
     color: #343434;
font-size: 14px;
font-weight: 700;
  }
}
.phone {display:flex;
  align-items:center;
  &__text {
     color: $main-color;
     font-weight: 700;
     text-decoration:none;
     padding-left: 5px;
     @media screen and (max-width: 767px){
    font-size: 12px;
     }
     
  }
}
.logo{
  @media screen and (max-width: 767px){
    width: 50%
 }
 & img{
    @media screen and (max-width: 767px){
       width: 100%;
       background-position: center;
       background-repeat: no-repeat;
       background-size: cover;
    }
 }
}

.subcategories-list {
  list-style: none;
  padding: 0;
}

.subcategory-item {
  margin-bottom: 8px;
  padding: 8px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.brand-container{
  background: var(--background-color-white, #F5F5F5);
  padding-bottom: 60px;
}
.brand-title,
.actions__title{
  color: var(--Black, #282739);
font-size: 35px;
font-weight: 700;
text-transform: uppercase;
margin-top: 10px;
}

.brand-title{
  @media (max-width: 768px){
     font-size: 16px;
  }
}

.brand-nav{
  @media (min-width: 200px) and (max-width: 410px){
    display: flex;
flex-wrap: wrap;
 }
  @media (min-width: 410px) and (max-width: 768px){
    display: grid;
grid-template-columns: repeat(3, 1fr)
 }
  display: grid;
grid-template-columns: repeat(8, 1fr)
}
.brands-list{
list-style: none;
  @media (max-width: 767px){
    font-size: 16px;
  }

}
.brand-image{
  @media (max-width: 767px){
    width: 300px;
    height: 300px;
  }
  width: 500px;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.brand-header{
  
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 50px;
}

.brand-link,
.actions__link{
  
  color: var(--main-color-2, rgba(4, 42, 116, 0.90));
text-align: center;
font-size: 22px;
font-weight: 600;
text-decoration: none;
border-radius: 5px;
border: 1.384px solid var(--main-color-2, rgba(4, 42, 116, 0.90));
padding: 11px 26px 0;
}
.brand-link{
  @media (max-width: 767px){
    font-size: 16px;
  }
}
.modal{
  z-index: 500
}
// Кнопки
.btn-callback {
  @media (max-width: 768px){
    display: none;
  }
  color: #FFF;
  border-radius: 5px;
  background-color: $orange;
  padding: 13px 108px;
  border: none;
  cursor: pointer;
}
.btn-product-cart{
  @media (max-width: 768px){
    margin-bottom: 10px;
    padding: 13px 80px;
    font-weight: 800;
  
  }
  color: #FFF;
  border-radius: 5px;
  background-color: $orange;
  padding: 13px 108px;
  border: none;
  cursor: pointer;
}
.btn-compare { 
  @media (max-width: 768px){
    display: none;
  }
  
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  &__count{
    color: #FFF;
text-align: center;
font-size: 9px;
background-color: $orange;
border-radius: 95.818px;
padding: 2px 4px;
position: absolute;
top: -20%;
left: 60%;
  }
}
.btn-cart {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  &__count{
    color: #FFF;
text-align: center;
font-size: 9px;
background-color: $orange;
border-radius: 95.818px;
padding: 2px 4px;
position: absolute;
top: -10%;
left: 70%;
  }
}
.btn-search {
  background-color:$orange;
  border-radius: 5px;
box-shadow: 0px 7.74737px 15.49474px 0px rgba(0, 0, 0, 0.06);
padding: 5px 10px;
cursor: pointer;
}

.btn-oneclick{
   background-color: transparent;
   color: #FB820C;
text-align: center;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.54px;
border-radius: 5px;
padding: 12px 25px;
border: 1.096px solid #F2994A;
cursor: pointer;
}
.btn-productcart{
  @media (max-width: 768px) {
    font-size: 5px;
    padding: 1px;
 }
  background-color: $main-color;
  padding: 8px 10px 8px 6px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
}
