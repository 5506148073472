.modal {
   display: none;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.70);
   justify-content: center;
   align-items: center;
 }
 
 .modal.active {
   display: flex;
 }
 
 .modal__content {
   background-color: #fff;
   border-radius: 5px;
   text-align: center;
 }
 
 .modal__content p {
   margin-bottom: 20px;
 }
 
 .close {
   padding: 10px 20px;
   background-color: #007bff;
   color: #fff;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   align-items: center;
   justify-content: center;
 }
 
 .modal__content button:hover {
   background-color: #0056b3;
 }