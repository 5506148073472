.comments-container{
   background: var(--background-color-white, #F5F5F5);
   display:grid;
  grid-template-columns: 1fr 3fr;
  gap: 50px;
}
.comments-content ul{
   list-style: none;
}
.comments-block{
   margin-bottom: 15px;
}
.comments-body{
      max-width: 80%;
      display:flex;
      justify-content: space-evenly;
      border-radius: 20px;
   background: var(--1, #fff);
   box-shadow: 0px 1.898px 4.745px 0px rgba(0, 0, 0, 0.40);
   padding: 30px;
   align-items: center;
   list-style: none;
   &__text{
      font-weight: 800;
   font-style: italic; 
   font-size: 20px;
   margin-bottom: 20px;
   margin-top: 20px;
   }
   &__fullname{
      color: $main-color;
      font-size: 20px;
      font-weight: 600;
   }
   &__data{
      font-style: italic;
      margin-bottom: 20px;
   margin-top: 20px;
   }
   &__image{
      width: 100px;
      height: 100px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-right: 50px;
      
   } 
 &__user{
   flex: 1;
   max-width: 100%;
 }
 &__uknown{
   flex: 1;
   max-width: 100%;
 }
}



.comments-textarea {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 20px;
}

textarea {
   width: 100%;
   padding: 10px;
   margin-bottom: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Додаємо тінь для 3D ефекту */
   transition: box-shadow 0.3s ease; /* Додаємо плавний перехід для тіні */
}

textarea:focus {
   outline: none;
   box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); /* Змінюємо тінь при фокусі */
}

.btn-comments {
   background-color: #4caf50;
   color: white;
   padding: 10px 15px;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   box-shadow: 0px 0px 10px rgba
}
