
.categories-menu-home{
   @media (max-width: 426px){
      display: grid;
      grid-template-columns: 1fr 1fr;
   }
   @media (max-width: 768px){
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
   }
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
   text-align: center;
   align-items: center;
   font-size: 18px;
   font-weight: 700;
letter-spacing: -0.54px;
}

.category-title{
   color: $black;
   &:hover{ color: #fff;
      background-color: $orange;}
}

.categories-title-sidebar{
font-size: 18px;
line-height: 160%;
}
.categories-title-other{display: none;
}
.categories-menu{
   @media (max-width: 426px){
      gap: 5px;
   }
   list-style: none;
   text-decoration: none;
   line-height: 160%;
   gap: 20px;
   &:hover{
      color: #fff;
      
   }
}
.categories-item{
   @media (max-width: 426px){
      max-width: 200px;
   }
   max-width: 227px;
   &:hover{background-color: $orange;}
}
.other-image{
   width: 100%;
   background-color: #fff;
   background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
   
}
.all-image{
   background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.subcategory-title{
   text-decoration: none;
   color:$black;
 }

 .categories-item {
   position: relative;
}

.categories-menu {
   --category-title-background: #fff; /* Колір фону тайтлу */
   --text-hover-color: #fff; /* Колір тексту при наведенні */
   /* Застосовуємо ширину та колір тексту тайтлу для кожного елементу меню */
   li {
      position: relative;
   }

   .subcategories-list {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: var(--category-title-background);
      border: 1px solid $orange; /* Додайте бажані стилі рамки */
      z-index: 1;
      width: 99.2%; /* Наслідує ширину тайтлу */

   }


   
   li:hover .subcategories-list {
      display: block;
      
   }
   .subcategories-item:hover {
      background-color: $orange;
     
   }
   .subcategories-item__title:hover{
      color: #fff;
   }
}
