.cart{
   background: var(--background-color-white, #F5F5F5);
}
.cart-container{
   @media (max-width: 426px){
      display: flex;
      flex-direction:column
    }
   display: grid;
   grid-template-columns: 2fr 1fr;
   gap: 20px;
}
.cart-box__title{
   text-align: center;
   align-items: center;
   margin-bottom: 58px;
}
.cart-box{
   background-color: #fff;
   padding: 40px 10px 30px;
}

.bill{
   border-radius: 5px;
background: rgba(29, 63, 129, 0.06);
   margin-top: 100px;
}
.all-bill{
   max-width: 95%;
   display: flex;
   justify-content: space-between;
   border-bottom: 1px solid rgba(171, 171, 171, 0.60);
   font-weight: 600;
line-height: normal;
font-size: 22px;
}
.products-cart{
   border-radius: 5px;
background: rgba(254, 119, 0, 0.03);
border-bottom: 1px solid rgba(171, 171, 171, 0.60);
padding: 20px;
&__image{
   width: 90px;
   height: 90px;
}
&__title{
   color: var(--Black, #282739);
font-size: 14.498px;
font-weight: 600;
line-height: 20px; /* 137.952% */
}
&__article{
   color: #000;
font-size: 14px;
font-weight: 400;
letter-spacing: -0.42px;
opacity: 0.5;
margin-right: 20px;
}
&__item{
   @media (max-width: 426px){
      display: flex;
      flex-direction:column
    }
      display: flex;
      justify-content: space-between;
      text-align: center;
align-items: center;
margin-bottom: 15px;
   }
}
.products-cart__quantity{
   display: flex;
   justify-content: space-between;
   max-width: 150px;
}
.quantity{
   &__btn{
      padding: 15px;
      font-weight: 900;
      border: none;
      background-color: rgba(29, 63, 129, 0.06);;
      cursor: pointer;
      
   }
   &__input{
      max-width: 30px;
      background-color: transparent;
      text-align: center;
      align-items: center;
      margin: 0 7px;
   }
}
.bill{
   padding: 20px;
}
.bill-check{
   display: flex;
   justify-content: space-between;
   color: var(--, #585555);
font-size: 14.498px;
font-weight: 500;
line-height: 24px; /* 165.543% */
margin-top: 20px;
}
.bill-products__image{
   display: none;
}
.cart-step{
   padding: 20px;
   border-radius: 5.02px;
background: #FFF;
margin-top: 15px;
}
.header-step{
   display: flex;
   justify-content: space-between;
   max-width: 350px;
   text-align: center;
   align-items: center;
   margin-bottom: 35px;
   &__step{
      color: #042A74;
      font-size: 22px;
      font-weight: 600;
   }
}
.content-step__input-group{
   display: flex;
   justify-content: center;
}
.content-step__input input{
   border-radius: 5px;
   background: #F5F5F5;
   padding: 10px;
   margin-bottom: 30px;
}
.input-phone input,
.input-name input{
   @media (max-width: 768px){
      width: 80%;
    }
   width: 465px;
   margin-right: 20px;
   margin-top: 10px;
   border: none;
}
.input-mail input{
   width: 96%;
   margin-top: 10px;
   border: none;
}
.bill-btn{
   color: #FFF;
text-align: center;
align-items: center;
font-size: 22px;
font-weight: 600;
   border-radius: 5px;
background: #46B520;
padding: 15px 25px;
cursor: pointer;
}
.delivery-options__radiobox{
   display: flex;
   flex-direction: column;
   & input{
      border-radius: 5px;
      background: var(--White, #FFF);
      /* Додайте інші стилі за необхідності */
   }
}
.delivery-body{
   display: flex;
   flex-direction: column;
   border-radius: 5px;
border: 1px solid #FE7700;
background: var(--White, #FFF);
padding: 10px;
& input{
   @media (max-width: 768px){
      width: 90%;
    }
   width: 1000px;
   border-radius: 5px;
border: 0.5px solid rgba(88, 85, 85, 0.50);
padding: 17px 16px 18px 18px;
}
& p{
   color: var(--Black, #282739);
line-height: 22px; /* 157.143% */
letter-spacing: -0.42px;
margin-bottom: 6px;
}
}
.content-step__pay{
   display: flex;
   justify-content: space-between;
   border-radius: 5px;
background: rgba(254, 132, 25, 0.05);
padding: 20px;
text-align: center;
align-items: center;
margin-bottom: 10px;
}
.btn-basket{
   padding: 10px;
   cursor: pointer;
   background-color: transparent;
   border: none;
}
.content-step__warning{
   font-size: 24px;
   margin-top: 10px
}