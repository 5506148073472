.sidebar__menu:active{
   color: $orange;
}
.sidebar-image{display:none}


.sidebar{
   width: 100%;
   .sidebar-container{
      @media (max-width: 768px){
      display: none;
      }
   }
}

.brands-filter{
.brand-nav{
   display: flex;
   flex-direction: column;
   
}
}

.brands-list{
   padding: 2px;
}
.brands-link{
   text-decoration: none;
   color: $black;
   &:hover{
      color: $orange;
   }
}
.sidebar-btn{
   background-color: transparent;
   border: none;
   color: $main-color;
   padding: 4px;
   font-size: 16px;
   &:hover{
      color: $orange;
      cursor: pointer;
   }
}
.size-filter{
   input{
      margin-bottom: 5px;
   }
}
.btn-filter{
   color: $main-color;
   font-size: 18px;
   font-weight: 900;
   background-color: transparent;
   border: 1px solid $main-color;
   cursor: pointer;
   padding: 6px 10px 10px 10px;
   &:hover{
      color: $orange;
   }
}
.place-of-use-filter,
.manufacturer-filter{
   margin-bottom: 4px;
}

.mobile-menu-btn {
   display: none;
   width: 100%;
   margin-bottom: 10px;
padding: 10px;
font-weight: 700;
font-size: 16px;
line-height: 20px;
/* identical to box height, or 125% */
letter-spacing: -0.03em;
background-color: $main-color;
color: #FFFFFF;
}

.mobile-menu-content {
   display: block;
}

@media (max-width: 768px) {
   .mobile-menu-btn {
      display: block;
   }
}