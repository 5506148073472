.user{
   &__container{
      @media (max-width: 768px){
         display: block;
         }
      display: grid;
      grid-template-columns: 1fr 2fr;
   }
   &__avatar{
      width: 400px;
      height: 400px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
   }
}
.body-order{
   margin-bottom: 45px;
   &__image{
      width: 150px;
      height: 150px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
   }
   &__products{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: center;
      align-items: center;
      list-style: none;
      text-decoration: none;
   }
   &__date{
      font-weight: 700;
      margin-top: 15px;
   }
   &__price{
      color: $main-color;
      font-weight: 700;
   }
}
