.notfound-container{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   min-height: 10vh;
}
.notfound-title{
   color: $main-color;
   font-size: 24px;
   
}
.notfound-text{font-size: 16px;
   font-weight: 700;
}
.notfound-link{
   margin: 10px 0;
}
.notfound-phone{
   text-decoration: none;
   color: $main-color;
}