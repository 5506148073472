
.actions{
   background-color: #f4f7fb;
   &__header{
      display: none;
   }
}
.home .actions__header{
   display:  flex;
   justify-content: space-between;
}

.products-container{
   @media (max-width: 768px) {
      display: block;
   }
   display:grid;
   grid-template-columns: 1fr 1fr;
   gap: 10px;
}

.product__list{
   display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.product__title{
   color: #000;
font-size: 14.498px;
font-weight: 600;
line-height: 20px; /* 137.952% */
}
.product-card__image{
   width: 100%;
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.products-content{
   @media (max-width: 426px) {
      display: flex;
      flex-wrap: wrap;
      
   }
   @media (min-width: 427px) and (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
   }
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr;
   gap: 20px;
}

.product-card{
   @media (max-width: 426px) {
      width: 224px;
      
    }
   @media (min-width: 427px) and (max-width: 768px) {
      max-width: 150px;
   }
   width: 224px;
   border-radius: 5.901px;
background: #FFF;
padding: 9px 14px;

   &__title{
      @media (max-width: 768px) {
         font-size: 14px;
         line-height: normal;
      }
      color: $black;
      font-size: 17.042px;
      font-weight: 500;
      line-height: 24.346px; /* 142.857% */
   }
   &:hover{
      border: 1px solid $orange;
      margin: -1px;
   }
   &__avail{
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: var(--Green, #75D14A);
      margin-right: 8px;
   }
   &__available{
      display: flex;
      align-items: center;
      margin-top: 20px;
   }
   &__price{
      color: #343434;
font-size: 22px;
font-weight: 600;
margin-top: 25px;
   }
}
.product-card__buy{
   @media (max-width: 768px) {
      padding-top: 10px;
   }
   display: flex;
   justify-content: space-between;
}
.available {
   background-color: var(--Green, #75D14A);
 }
 
 .unavailable {
   background-color: var(--Red, #FF0000);
 }

// Product 

 .singl-product{
   background: var(--background-color-white, #F5F5F5);
   
   &__container{
      @media (max-width: 768px){
         display: block;
       }
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 20px;
   }
   &__right{
   }
   &__block{
      background: #FFF;
      padding: 30px;
      border-radius: 5px;
      margin-bottom: 30px
   }
 }
 .product{
   &__price{
      margin: 30px 0;
   }
   &__btns{
      @media (max-width: 768px){
         flex-direction: column;
         width: 90%;
       }
      width: 500px;
      display: flex;
      justify-content: space-between;
   }
&__image{
   
   width: 100%;
   height: 410px;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
}

 }
 .product-visual {
   display: flex;
   flex-direction: column; 
  justify-content: space-between;
}

.product-rating {
   display: flex;
   align-items: center;
   margin-right: 35px
}
.product-image__second{
   width: 50%;
   height: 210px;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   cursor:pointer
}
.product-views {
   margin-top: 10px; /* Додано верхній відступ для відокремлення рейтингу та переглядів */
   display: flex;
   align-items: center; /* Вирівнювання переглядів по центру */
}
.product-article{
   width: 100px;
   display:flex;
   justify-content: space-around;
   margin-bottom: 40px;
}

.character{
   &__info{
      @media (max-width: 768px){
         width:90%
       }
      display: flex;
      justify-content: space-between;
      width: 500px;
      border-bottom: 1px solid #E0E0E0;
   }
}

.full-character{
   &__info{
      display: flex;
      justify-content: space-between;
      width: 80%;
      border-bottom: 1px solid #E0E0E0;
   }
}
.btns-info{
   @media (max-width: 768px){
      display: flex;
      flex-direction: column;
    }
   border-radius: 5px;
   background: #FFF;
}
.btn-info{
   color: #929292;
text-align: center;
font-size: 18px;
font-weight: 500;
line-height: 160%; /* 28.8px */
background-color: transparent;
border: none;
padding: 10px;
cursor: pointer;
&:hover,
&.active{
   color: #1E4082;
}
}
.product-content{
   border-radius: 5px;
background: #FFF;
padding: 50px;
margin-top: 2px;
}
.pagination{
   width: 98%;
   background-color: #FFF;
   justify-content: center;
   align-items: center;
   text-align: center;
   padding: 10px;
   margin: 0 0 0 5px;
   &__btn{
      border-radius: 5px;
border: 1.222px solid var(--text-outline, #CFD8DC);
color: var(--text-main-text, #1A1A1A);
font-size: 14.498px;
font-weight: 500;
line-height: 24px; /* 165.543% */
padding: 10px 15px;
cursor: pointer;
cursor: pointer;
   }
}

.current-page{
   border-radius: 5px;
border: 1px solid #CFD8DC;
   color: #000;
text-align: center;
font-size: 12.662px;
font-weight: 700;
line-height: 15.827px; /* 125% */
padding: 14px 18px;
cursor: pointer;
}
.button-page{
   border-radius: 5px;
border: 1px solid #CFD8DC;
   color: #000;
text-align: center;
font-size: 12.662px;
font-weight: 700;
line-height: 15.827px; /* 125% */
padding: 14px 18px;
margin: 0 5px;
cursor: pointer;
}
.button-page.current-page {
   background-color: $orange;
   color: #FFF;
font-size: 12.662px;
font-weight: 700;
line-height: 15.827px; /* 125% */  /* Колір тексту */
}

.description{
   &__title{
      color: #000;
font-size: 18px;
font-weight: 700;
letter-spacing: -0.54px;
margin-bottom: 20px;
   }
}


// Fragments

.similar-products {
   display: flex;
   flex-wrap: wrap;
   gap: 16px;
   justify-content: space-between;
   align-items: flex-start;

   
   ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      li {
         width: calc(33.33% - 16px);
         margin-bottom: 16px;

         @media (max-width: 767px) {
            width: 100%;
         }

         p {
            margin: 0;
         }
      }
   }

   .product-card {
     
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .product-card__image {
         width: 100%;
         height: auto;
         background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
      }

      .product-card__title {
         font-size: 16px;
         margin: 0;
      }

      .product-card__price {
         margin: 0;
      }

      .product-card__buy {
         display: flex;
         justify-content: space-between;
         align-items: center;
      }
   }
}
.product-rating__star:hover{
color: yellow;
cursor: pointer;
}

.catalog-sort{
   margin: 10px 26%;
   @media (max-width: 768px) {
      margin-left: 30px;
   }
}