.delivery-container{
   background: var(--background-color-white, #F5F5F5);
}
.delivery-title{
   @media only screen and (max-width: 768px){
      text-align:center;
      align-items:center;
   }
   color: var(--Black, #282739);
font-size: 25px;
font-weight: 700;
text-transform: uppercase;
margin-top: 75px;
}
.delivery-subtitle{
   
   font-size: 18px;
   &__info{
      max-width: 600px;
      color: var(--, #585555);
text-align: right;
font-size: 12px;
font-style: italic;
font-weight: 300;
line-height: normal;
letter-spacing: -0.36px;
   }
}
.delivery-text{
   @media only screen and (max-width: 768px){
     display: none
   }
}
.delivery-warning{
   display: flex;
   justify-content: space-between;
   border-radius: 5px;
background: rgba(243, 41, 28, 0.25);
padding: 14px 17px;
margin-top: 45px;
margin-bottom: 50px;
&__text{
   margin-left: 20px;
}
}

.delivery-courier{
   @media only screen and (max-width: 768px){
      display: none;
   }
   display: flex;
   justify-content: space-between;
}
.courier-empty__text{
   padding: 15px;
   border-bottom: 1px solid #F5F5F5;;
}
.courier-pay__text{
   max-width: 270px;
   height: 40px;
   align-items: center;
   padding-top: 10px;
text-align: center;
border-bottom: 1px solid #F5F5F5;
border-radius: 5.02px;
background: #FFF;
}
.courier__text{
   max-width: 270px;
   height: 30px;
   color: var(--, #585555);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.42px;
border-bottom: 1px solid #F5F5F5;
padding-top: 10px;
text-align: center;
border-radius: 5.02px;
background: #FFF;
}
.courier-image{
   align-items: center;
text-align: center;
}
.courier-image__empty{
   visibility: hidden;
}

.delivery-payment{
   @media only screen and (max-width: 768px){
      display: block;
      text-align:center;
      align-items:center;
   }
   display: flex;
   justify-content: space-between;
   margin-bottom: 100px;
   &__item{
      max-width: 290px;
      text-align: center;
      align-items: center;
      margin-top: 30px;
   }
}
.mobile-courier{
   display: none;
}
.payment,
.mobile-courier{
   @media only screen and (max-width: 768px) {
      display: block;
      text-align: center;
      align-items: center;
      margin: 0 auto;
      margin-bottom: 25px;
    }
   border-radius: 5px;
border: 1px solid #FE7700;
background: var(--White, #FFF);
   padding: 17px;
   &__title{
      color: $black;
font-size: 16px;
font-weight: 600;
line-height: 129%; /* 20.64px */
margin-bottom: 22px;
   }
   &__text{
      color: $black;
font-size: 12px;
letter-spacing: -0.36px;
margin-bottom: 16px;
   }
}